<template>
  <div class="file-upload">
    <label :for="uid" class="cursor-pointer position-relative paperclip">
      <i class="bi-paperclip position-absolute"></i>
      <slot>{{ title }}</slot>
      <small v-if="accept" class="text-nowrap text-muted accept">({{ accept }})</small>
    </label>
    <input @change="uploadFiles"
           :multiple="multiple"
           :id="uid"
           :accept="accept"
           type="file"
           class="d-none">
    <div v-for="(file, index) in files" v-bind:key="index">
      <div>
        <div v-if="clearable" v-on:click="removeFile(index)" class="cursor-pointer float-right">
          <i class="bi-x"></i>
        </div>
        <div>
          <a :href="file.downloadLink" target="_blank">
            {{ file.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUploadManager } from "@/utils/di";

let i = 0;

export default {
  name: "FileUpload",
  props: {
    files: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "Прикрепить файл"
    },
    accept: {
      type: String,
      default: ".pdf, .jpg, .jpeg, .png, .gif, .tiff, .doc, .docx"
    }
  },
  computed: {
    uid() {
      return 'file-upload-' + ++i;
    }
  },
  methods: {
    async uploadFiles(e) {
      const manager = await createUploadManager();
      for (let i = 0; i < e.target.files.length; i++) {
        manager.uploadFile(e.target.files[i])
            .then(data => {
              if (!this.multiple) {
                this.files.splice(0, this.files.length);
              }
              this.files.push(data);
            });
      }
    },
    removeFile: function (index) {
      this.clearable && this.$confirm("Вы хотите удалить файл?", () => {
        this.files.splice(index, 1);
      });
    }
  }
};
</script>

<style lang="scss">
.file-upload {
  .paperclip {
    padding-left: 2.5rem;

    .bi-paperclip::before {
      border: 0.125rem solid #21e58a;
      border-radius: 1rem;
      height: 2rem;
      width: 2rem;
      line-height: 2rem;
      text-align: center;
      margin-left: -2.5rem;
    }

    .accept {
      display: block;
    }
  }
}
</style>
