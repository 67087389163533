<template>
  <div class="footer-menu">
    <div class="container-xl">
      <div class="row footer-menu-columns">
        <div class="col-12 col-lg-4 mb-3 col-contacts">
          <div class="footer-logo">
            <public-link to="/">
              <img alt="Lendly" src="@/assets/logo-fixed.svg">
            </public-link>
          </div>
          <div class="mt-3">
            ООО "Лендли" / ИНН: 7814733360<br>
            197342, город Санкт-Петербург, наб. Чёрной речки,<br>
            д. 47, стр. 2, помещ. 1-н, ч. 34
            <div class="mt-3 contact-socials">
              <a href="https://t.me/lendly" target="_blank">
                <img src="@/assets/img/emqz8ytto3rn40-40-tele.png"
                     id="exifviewer-img-42"
                     exifid="648497107"
                     oldsrc="@/assets/img/emqz8ytto3rn40-40-tele.png">
              </a>
              <a href="https://vk.com/lendlyru" target="_blank">
                <img src="@/assets/img/vk-logo.svg"
                     id="exifviewer-img-41"
                     exifid="-1082351242"
                     oldsrc="@/assets/img/azkngadniycg768px-facebook-logo-2019-40-40.png">
              </a>
              <a href="https://www.youtube.com/@LENDLYNEWS" target="_blank">
                <img src="@/assets/img/youtube-logo.svg"
                     id="exifviewer-img-41"
                     exifid="-1082351242"
                     oldsrc="@/assets/img/azkngadniycg768px-facebook-logo-2019-40-40.png">
              </a>
              <a href="mailto:support@lendly.ru" class="ml-3">support@lendly.ru</a>
              <a href="https://t.me/lendlysupport" class="ml-3">@lendlysupport</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <ul>
<!--            <li>-->
<!--              <public-link to="/page/how-it-work">-->
<!--                Как это работает-->
<!--              </public-link>-->
<!--            </li>-->
            <li>
              <public-link to="/page/aboutus">
                О платформе
              </public-link>
            </li>
            <li>
              <public-link to="/faq">
                FAQ
              </public-link>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <ul>
            <li>
              <public-link to="/page/raskrytie-informatsii">
                Раскрытие информации
              </public-link>
            </li>
            <li>
              <public-link to="/page/aktualnye-dokumenty">
                Актуальные документы
              </public-link>
            </li>
            <li>
              <public-link to="/page/arkhiv-dokumentov">
                Архив документов
              </public-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-center">&copy; 2020 - {{ date.getFullYear() }} OOO "Лендли"</div>
    </div>
  </div>
</template>

<script>
import PublicLink from "./PublicLink";

export default {
  name: "FooterMenu",
  components: {
    PublicLink
  },
  data() {
    return {
      date: new Date()
    };
  }
}
</script>

<style lang="scss">
.footer-menu {
  background: #f5f5f5;
  color: #6c757d;
  padding: 3rem;

  a {
    color: #6c757d;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    position: relative;
    padding-left: 10px;
    margin: 1rem 0;
  }

  ul li:before {
    position: absolute;
    content: "-";
    margin-left: -10px;
  }

  .footer-menu-columns {
    font-size: 0.8rem;

    .col-contacts  {
      .footer-logo {
        img {
          height: 2rem;
        }
      }

      hr {
        margin: 0.25rem 0;
      }

      .contact-email {
        font-size: 0.9rem;
      }

      .contact-socials a {
        margin: 0 0.25rem;

        img {
          height: 2rem;
        }
      }
    }
  }
}
</style>
