<template>
  <span class="money">
    <span v-html="formatAmount()"></span>&nbsp;<span class="ruble" v-if="currency">&#8381;</span>
  </span>
</template>

<script>
import { formatAmount } from "../formatters/formatAmount";

export default {
  name: "Money",
  props: {
    money: {
      type: Object,
      default() {
        return {
          amount: 0,
          currencyCode: "RUB"
        };
      }
    },
    decimal: {
      type: Boolean,
      default() {
        return false;
      }
    },
    currency: {
      type: Boolean,
      default() {
        return true;
      }
    },
    thousands: {
      type: Boolean,
      default() {
        return true;
      }
    },
    decimalDelimiter: {
      type: String,
      default() {
        return ",";
      }
    },
    thousandsDelimiter: {
      type: String,
      default() {
        return "&nbsp;";
      }
    }
  },
  methods: {
    formatAmount() {
      return this.money && formatAmount(this.money.amount / 100, {
        decimal: this.decimal,
        thousands: this.thousands,
        decimalDelimiter: this.decimalDelimiter,
        thousandsDelimiter: this.thousandsDelimiter
      });
    }
  }
};
</script>
