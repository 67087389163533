<template>
  <input @input="(e) => updateValue(e.target.value)" type="text">
</template>

<script>
export default {
  name: "InputDigits",
  props: {
    modelValue: null,
    maxlength: null
  },
  mounted() {
    this.updateValue(this.modelValue);
  },
  updated() {
    this.updateValue(this.modelValue);
  },
  methods: {
    updateValue(value) {
      value = value ? value.replace(/\D/g, '') : "";
      const maxlength = this.maxlength && +this.maxlength;
      if (maxlength && value.length > maxlength) {
        value = value.substr(0, maxlength)
      }
      if (this.$el) {
        this.$el.value = value;
        this.$emit("update:modelValue", value);
      }
    }
  }
}
</script>
