import { ProjectStatus } from "@/utils/api-client";

export default {
  [ProjectStatus.REVIEW]: "На рассмотрении",
  [ProjectStatus.REVIEW_APPROVED]: "Заполнение информации",
  [ProjectStatus.REVIEW_REJECTED]: "Отклонено",
  [ProjectStatus.FINAL_REVIEW]: "На рассмотрении",
  [ProjectStatus.FINAL_REVIEW_APPROVED]: "Одобрено",
  [ProjectStatus.FINAL_REVIEW_REJECTED]: "Отклонено",
  [ProjectStatus.FUND_RAISING]: "Активный",
  [ProjectStatus.FINISHED]: "Проинвестирован",
  [ProjectStatus.LOAN_PAYMENT]: "Выплата долга",
  [ProjectStatus.PAID]: "Завершен",
  [ProjectStatus.REFUNDED]: "Отменен"
};
