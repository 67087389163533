<template>
  <div class="calendar">
    <date-picker ref="datepicker"
                 v-model="date"
                 :locale="locale"
                 :attributes="attributes"
                 @update:modelValue="$emit('input', date)"/>
  </div>
</template>

<script>
// @ts-ignore
import { DatePicker } from "v-calendar";

export default {
  name: "Calendar",
  components: {
    DatePicker,
  },
  props: {
    date: {
      type: Date,
      default: new Date(),
    },
    selectedDates: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      locale: "ru",
      attributes: [ {
        highlight: {
          key: "selectedDays",
          fillMode: "outline",
          color: "green",
        },
        dates: this.selectedDates,
      } ],
    };
  },
  watch: {
    selectedDates() {
      this.attributes = [ {
        highlight: {
          key: "selectedDays",
          fillMode: "outline",
          color: "green",
        },
        dates: this.selectedDates,
      } ];
    },
  },
};
</script>
