<template>
  <div v-if="currency" class="input-group">
    <input v-bind="attributes"
           ref="input"
           @input="(e) => updateValue(e.target.value)"
           @blur="(e) => updateValue(e.target.value, true)"
           type="text">
    <div class="input-group-append">
      <div class="input-group-text"><span class="ruble">&#8381;</span></div>
    </div>
  </div>
  <input v-else v-bind="attributes"
         ref="input"
         @input="(e) => updateValue(e.target.value)"
         @blur="(e) => updateValue(e.target.value, true)"
         type="text">
</template>

<script>
import { formatAmount } from "../formatters/formatAmount";

export default {
  name: "InputMoney",
  inheritAttrs: false,
  props: {
    modelValue: null,
    min: null,
    max: null,
    allowFractions: {
        type: Boolean,
        default: false
    },
    thousands: {
      type: Boolean,
      default: true
    },
    thousandsDelimiter: {
      type: String,
      default: " "
    },
    currency: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      value: null
    };
  },
  computed: {
    attributes() {
      return Object.keys(this.$attrs)
          // .filter((key) => typeof this.$attrs[key] === "string")
          .reduce((prev, key) => ({ ...prev, [key]: this.$attrs[key] }), {});
    }
  },
  mounted() {
    this.updateValue(this.modelValue);
  },
  updated() {
    this.updateValue(this.modelValue);
  },
  methods: {
    updateValue(value, withLimits) {
      let amount;
      if (this.allowFractions) {
          let sanitizedValue = value;
          if (sanitizedValue) sanitizedValue = sanitizedValue.toString().replace(/(,)/g, '.');
          if (sanitizedValue) sanitizedValue = sanitizedValue.toString().replace(/(\/)/g, '.');
          if (sanitizedValue) sanitizedValue = sanitizedValue.toString().replace(/([^.0-9])/g, '');
          this.$refs.input.value = sanitizedValue;
          this.$emit("update:modelValue", sanitizedValue);
          return;
      }
      let sanitizedValue = value || value === 0 ? value.toString().replace(/\D+/g, '') : "";
      if (sanitizedValue.length > 0) {
        if (sanitizedValue.length > 15) {
          sanitizedValue = sanitizedValue.substr(0, 15);
        }

        const parsedAmount = parseInt(sanitizedValue);
        if (!isNaN(parsedAmount)) {
          amount = parsedAmount;

          if (withLimits) {
            const min = +this.min;
            if (this.min !== null && !isNaN(min) && amount < min) {
              amount = min;
            }

            const max = +this.max;
            if (this.max !== null && !isNaN(max) && amount > max) {
              amount = max;
            }
          }
        }
      }

      if (amount !== undefined) {
        value = formatAmount(amount, {
          thousands: this.thousands,
          thousandsDelimiter: this.thousandsDelimiter
        });

        if (this.$refs.input) {
          this.$refs.input.value = value;
          this.$emit("update:modelValue", amount);
        }
      }
    }
  }
}
</script>
