import { Collection } from "@/utils/api-client";

export class DataCollection<T> {
  private _loading = false;
  private _count = 0;
  private _totalCount = 0;
  private _items: Array<T> = [];

  constructor(readonly prefix = "") {}

  get loading(): boolean {
    return this._loading;
  }

  get count(): number {
    return this._count;
  }

  get totalCount(): number {
    return this._totalCount;
  }

  get items(): Array<T> {
    return this._items;
  }

  delete(index: number): Array<T> {
    return this._items.splice(index, 1);
  }

  startLoading(): void {
    this._loading = true;
  }

  finishLoading(): void {
    this._loading = false;
  }

  setCollection(collection: Collection<T>): void {
    this._count = collection.count;
    this._totalCount = collection.totalCount;
    this._items = collection.items;
  }
}
