type QueryObject = {
  [key: string]: any;
};

export function buildRouterQuery(data: QueryObject, prefix?: string): string {
  const str: Array<string> = [];
  if (data instanceof Array) {
    if (data.length > 0) {
      str.push(prefix + ":[" + data.join(",") + "]")
    }
  } else if (data instanceof Date) {
    const year = data.getFullYear();
    const month = data.getMonth() + 1;
    const sMonth = month < 10 ? "0" + month : month;
    const day = data.getDate();
    const sDay = day < 10 ? "0" + day : day;
    str.push(prefix + ":" + [year, sMonth, sDay].join("-"))
  } else if (data !== null && typeof data === "object") {
    for (const name in data) {
      const key: string = prefix ? prefix + "." + name : name;
      const value: any = data[name];
      if (value instanceof Array || (value !== null && typeof value === "object")) {
        const res = buildRouterQuery(value, key);
        res && str.push(res);
      } else if (value !== null && value !== "") {
        str.push(key + ":" + value);
      }
    }
  }
  return str.join(";");
}

export function parseRouterQuery(query: string): QueryObject {
  const results: QueryObject = {};
  query.split(";").forEach((part) => {
    const [key, value] = part.split(":");
    const parts = key.split(".");
    let r = results;
    parts.forEach((k, index) => {
      if (index === parts.length - 1) {
        if (value[0] === "[") {
          r[k] = value.substr(1, value.length - 2).split(",")
        } else if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
          r[k] = new Date(value);
        } else {
          r[k] = value;
        }
      } else {
        if (r[k] === undefined) {
          r[k] = {};
        }
        r = r[k];
      }
    });
  });
  return results;
}