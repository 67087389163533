<template>
  <div class="project-offers">
      <paginated-list :count="items.length"
                      :total-count="totalCount"
                      :data-loading="dataLoading"
                      @update="loadData"
      >
        <template v-slot:no-items><i>Нет доступных предложений</i></template>
        <div class="card mb-3" v-for="item in items" :class="{ 'without-actions': !buy }">
          <div class="card-body">
            <div class="row">
              <div class="col-name">
                <div class="h6">{{ item.project.name }}</div>
                <div class="small text-muted">ID {{ item.project.paymentCode }}</div>
              </div>
              <div class="col-buy-back">
                <img v-if="item.buyBack" src="@/assets/img/bay_back.png" alt="Buy Back">
              </div>
              <div class="col-investment-money">
                <div class="small text-muted">Размер предложения</div>
                <div>
                  <money :money="item.investmentMoney"/>
                </div>
              </div>
              <div class="col-price">
                <div class="small text-muted">Цена</div>
                <div>
                  <money :money="item.price"/>
                </div>
              </div>
              <div class="col-interest-rate">
                <div class="small text-muted">Ставка по договору</div>
                <div>{{ item.project.interestRate }}%</div>
              </div>
              <div class="col-left-months">
                <div class="small text-muted">Оставшийся срок</div>
                <TimeLeftField :left-days="item.leftDays" :left-months="item.leftMonths"/>
              </div>
              <div class="col-ltv">
                <div class="small text-muted">LTV</div>
                <div>{{ item.ltv ? (Math.round(item.profit * 10) / 10) + "%" : "-" }}</div>
              </div>
              <div class="col-profit">
                <div class="small text-muted">Доходность</div>
                <div>{{ Math.round(item.profit * 10) / 10 }}%</div>
              </div>
              <div v-if="buy" class="col-actions">
                <router-link v-if="item.buyBack"
                             :to="{ name: 'buyBackOffer', params: { uuid: item.uuid } }"
                             class="btn btn-success btn-sm">Инвестировать</router-link>
                <router-link v-if="!item.buyBack"
                             :to="{ name: 'secondaryMarketOffer', params: { uuid: item.uuid } }"
                             class="btn btn-success btn-sm">Инвестировать</router-link>
              </div>
            </div>
          </div>
        </div>
      </paginated-list>
  </div>
</template>

<script>
import { createOfferProvider } from "@/utils/di";
import Money from "./Money";
import PaginatedList from "./PaginatedList";
import TimeLeftField from "./TimeLeftField";

export default {
  name: "ProjectOffers",
  components: {
    Money,
    PaginatedList,
    TimeLeftField
  },
  props: {
    projectUuid: {
      type: String,
      required: true
    },
    buy: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      items: [],
      totalCount: 0,
      dataLoading: false,
    };
  },
  methods: {
    async loadData(page, perPage) {
      this.items = [];
      this.dataLoading = true;

      try {
        const provider = await createOfferProvider();
        const collection = await provider.getListProjectSelling(this.projectUuid, page, perPage);
        this.items = collection.items;
        this.totalCount = collection.totalCount;
      } finally {
        this.dataLoading = false;
      }
    }
  }
}
</script>

<style lang="scss">
.project-offers {
  .card .card-body {
    padding: 0.5rem 1.5rem;
  }

  .col-name,
  .col-buy-back,
  .col-investment-money,
  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-profit,
  .col-actions {
    padding: 1%;
  }

  .col-buy-back,
  .col-investment-money,
  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-profit,
  .col-actions {
    text-align: center;
  }

  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-profit {
    position: relative;
  }

  .col-price:before,
  .col-interest-rate:before,
  .col-left-months:before,
  .col-ltv:before,
  .col-profit:before {
    content: "";
    background: #ecedef;
    position: absolute;
    bottom: 25%;
    left: 0;
    height: 50%;
    width: 0.125rem;
  }

  .col-left-months:before {
    background: none;
  }

  .col-name {
    width: 90%;
  }

  .col-buy-back {
    width: 10%;
  }

  .col-investment-money {
    width: 34%;
  }

  .col-price {
    width: 33%;
  }

  .col-interest-rate {
    width: 33%;
  }

  .col-left-months {
    width: 34%;
  }

  .col-ltv {
    width: 33%;
  }

  .col-profit {
    width: 33%;
  }

  .col-actions {
    width: 100%;
  }

  .col-buy-back img {
    height: 2.5rem;
  }

  @media (min-width: 768px) { // Bootstrap MD
    .col-left-months:before {
      background: #ecedef;
    }

    .col-name {
      width: 90%;
    }

    .col-buy-back {
      width: 10%;
    }

    .col-investment-money {
      width: 18%;
    }

    .col-price {
      width: 18%;
    }

    .col-interest-rate {
      width: 17%;
    }

    .col-left-months {
      width: 17%;
    }

    .col-ltv {
      width: 14%;
    }

    .col-profit {
      width: 16%;
    }

    .col-actions {
      width: 100%;
    }
  }

  @media (min-width: 992px) { // Bootstrap LG
    .col-name {
      width: 21%;
    }
    .col-buy-back {
      width: 5%;
    }

    .col-investment-money {
      width: 13%;
    }

    .col-price {
      width: 10%;
    }

    .col-interest-rate {
      width: 12%;
    }

    .col-left-months {
      width: 11%;
    }

    .col-ltv {
      width: 8%;
    }

    .col-profit {
      width: 8%;
    }

    .col-actions {
      width: 12%;
      padding-top: 1.5rem;
    }

    .col-buy-back img {
      margin-top: 0.75rem;
    }

    .without-actions {
      .col-name {
        width: 21%;
      }

      .col-investment-money {
        width: 15%;
      }

      .col-price {
        width: 12%;
      }

      .col-interest-rate {
        width: 14%;
      }

      .col-left-months {
        width: 13%;
      }

      .col-ltv {
        width: 10%;
      }

      .col-profit {
        width: 10%;
      }
    }
  }
}
</style>
