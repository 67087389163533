<template>
  <div>
    <money :money="info && info.sum"/>
    <span v-if="counter"> / {{ info ? info.count : 0 }}</span>
  </div>
</template>

<script>

import Money from "./Money";

export default {
  name: "InvestedInfo",
  components: {
    Money
  },
  props: {
    info: null,
    counter: {
      type: Boolean,
      default: true
    },
  }
}
</script>
