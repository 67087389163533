<template>
  <span class="date-format" v-html="formatDate()"></span>
</template>

<script>
import moment from "moment";

export default {
  name: "DateFormat",
  props: {
    date: {
      type: String
    },
    format: {
      type: String,
      default() {
        return "DD.MM.YYYY";
      },
    },
  },
  methods: {
    formatDate() {
      return this.date && moment(this.date).format(this.format);
    },
  },
};
</script>
