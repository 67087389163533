<template>
  <div v-if="show" class="company-search">
    <div class="modal fade show d-block"
         data-backdrop="static"
         data-keyboard="false"
         tabindex="-1"
         role="dialog"
         aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">Поиск компании</div>
          </div>
          <div class="modal-body">
            <div class="form-group company-search-input">
              <input @input="(e) => search(e.target.value)"
                     ref="query"
                     class="form-control"
                     type="text"
                     placeholder="Название компании или ИНН"
                     autocomplete="off">
              <ul v-if="searchResults.length > 0" class="list-group">
                <li v-for="company in searchResults"
                    @click="choose(company)"
                    class="list-group-item"
                >
                  {{ company.name + ' ' + company.inn }}
                </li>
              </ul>
              <div v-else-if="query" class="text-center mt-3"><i>Нет результатов</i></div>
              <div v-else class="text-center mt-3"><i>Введите название компании или ИНН</i></div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="close" type="button" class="btn btn-outline-danger">
              Отмена
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { createCompanyProvider } from "@/utils/di";

const cachedOptions = {};

export default {
  name: "CompanySearch",
  props: {
    show: Boolean
  },
  data() {
    return {
      searchResults: [],
      query: null,
    };
  },
  methods: {
    async search(query) {
      query = query.replace(/^\s+/, "").replace(/\s+$/, "");
      this.query = query;
      if (query) {
        if (!cachedOptions[query]) {
          const provider = await createCompanyProvider();
          cachedOptions[query] = await provider.suggestions(query);
        }
        this.searchResults = cachedOptions[query];
      } else {
        this.searchResults = [];
      }
    },
    choose(company) {
      this.$emit("choose", company);
      this.close();
      this.$refs.query.value = "";
      this.searchResults = [];
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.company-search {
  .modal-dialog {
    .modal-content {
      min-height: 20rem;
    }
  }

  .company-search-input {
    .list-group {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      min-height: 10rem;
      max-height: calc(100vh - 17rem);
      overflow-y: auto;

      .list-group-item:first-child {
        border-top: none;
      }

      .list-group-item:hover {
        background: #228d9e;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
