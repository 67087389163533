<template>
  <div class="paginator" v-if="displaySinglePage || countPages > 1">
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li v-if="isFirstPage" class="page-item disabled">
          <span class="page-link">&lsaquo;</span>
        </li>
        <li v-if="!isFirstPage" class="page-item">
          <router-link class="page-link" :to="generatePreviousPageUrl()">&lsaquo;</router-link>
        </li>
        <li v-for="page in pages" class="page-item" :class="{ active: isCurrentPage(page) }">
          <span v-if="isCurrentPage(page)" class="page-link">{{ page }}</span>
          <router-link v-if="!isCurrentPage(page)" class="page-link" :to="generatePageUrl(page)">{{ page }}</router-link>
        </li>
        <li v-if="isLastPage" class="page-item disabled">
          <span class="page-link">&rsaquo;</span>
        </li>
        <li v-if="!isLastPage" class="page-item">
          <router-link class="page-link" :to="generateNextPageUrl()">&rsaquo;</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
const maxPages = 7;

export default {
  name: "Paginator",
  props: {
    totalCount: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 25
    },
    displaySinglePage: {
      type: Boolean,
      default: true
    },
    firstPage: {
      type: Number,
      default: 1
    },
    defaultPerPage: {
      type: Number,
      default: 25
    },
    pageParam: {
      type: String,
      default: "page"
    },
    perPageParam: {
      type: String,
      default: "per_page"
    }
  },
  computed: {
    countPages() {
      return Math.ceil(this.totalCount / this.perPage);
    },
    pages() {
      const pages = this.countPages <= 1 ? [this.firstPage] : [...Array(this.countPages + this.firstPage).keys()].slice(this.firstPage);
      let posLeft = 0;
      if (pages.length > maxPages) {
        const minLeft = Math.floor(maxPages / 2);
        const maxLeft = this.countPages - maxPages;
        if (this.page > minLeft) {
          posLeft = this.page - minLeft - 1;
        }
        if (posLeft > maxLeft) {
          posLeft = maxLeft;
        }
      }
      return pages.slice(posLeft, posLeft + maxPages);
    },
    isFirstPage() {
      return this.page === this.pages[0];
    },
    isLastPage() {
      return this.page === this.pages[this.pages.length - 1];
    }
  },
  methods: {
    isCurrentPage(page) {
      return this.page === page;
    },
    generatePageUrl(page) {
      const { path, query } = this.$route;
      const queryParams = { ...query };

      delete queryParams[this.pageParam];
      delete queryParams[this.perPageParam];

      if (page > this.firstPage) {
        queryParams[this.pageParam] = page;
      }

      if (this.perPage !== this.defaultPerPage) {
        queryParams[this.perPageParam] = this.perPage;
      }

      return { path, query: queryParams };
    },
    generatePreviousPageUrl() {
      if (this.isFirstPage) {
        return this.generatePageUrl(this.firstPage);
      }
      return this.generatePageUrl(this.page - 1);
    },
    generateNextPageUrl() {
      if (this.isLastPage) {
        return this.generatePageUrl(this.countPages);
      }
      return this.generatePageUrl(this.page + 1);
    }
  }
}
</script>
