<template>
  <div class="processing">
    <div v-if="show"
         class="modal fade show d-block"
         data-backdrop="static"
         data-keyboard="false"
         tabindex="-1"
         role="dialog"
         aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <slot>Обработка данных...</slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "Processing",
  props: {
    show: {
      type: Boolean,
      required: true
    },
  }
};
</script>

<style lang="scss">
.processing {
  .modal-dialog {
    width: 80%;
    margin: 0 auto;
    top: 40%;

    @media (min-width: 576px) { // Bootstrap SM
      width: auto;
    }

    .modal-content {
      .modal-body {
        text-align: center;
      }
    }
  }
}
</style>
