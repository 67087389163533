<template>
  <div class="c-multi-select">
    <div class="dropdown">
      <button class="form-control text-left dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
      >
        <span v-if="countSelected === 0" class="text-muted">{{ placeholder }}</span>
        <span v-if="countSelected === 1">{{ getFirstSelected().text }}</span>
        <span v-if="countSelected > 1" class="text-muted font-italic">Выбрано {{ countSelected }}</span>
      </button>
      <div @click="(e) => e.stopPropagation()" class="dropdown-menu w-100 p-0" aria-labelledby="dropdownMenuButton">
        <a v-for="(option, index) in normalizedOptions"
           @click="(e) => toggleOption(e, index)"
           :class="{ active: toggle[index] }"
           class="dropdown-item"
           href="#"
        >
          {{ option.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CMultiSelect",
  props: {
    model: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {
      toggle: []
    };
  },
  mounted() {
    this.updateToggle();
  },
  computed: {
    normalizedOptions() {
      return this.options.map((option) => {
        const value = option.value !== undefined ? option.value : option;
        const text = option.text !== undefined ? option.text : option;
        return { value, text };
      });
    },
    countSelected() {
      return this.toggle.reduce((prev, item) => prev + item, 0);
    }
  },
  methods: {
    getFirstSelected() {
      const index = this.toggle.indexOf(true);
      if (index > -1) {
        return this.normalizedOptions[index];
      }
    },
    toggleOption(e, index) {
      e.preventDefault();
      this.toggle[index] = !this.toggle[index];

      this.model.splice(0, this.model.length);
      for (let i = 0; i < this.toggle.length; i++) {
        if (this.toggle[i]) {
          const option = this.normalizedOptions[i];
          this.model.push(option.value);
        }
      }

      this.$emit("input", this.model);
    },
    updateToggle() {
      this.toggle = [];
      for (let i = 0; i < this.normalizedOptions.length; i++) {
        const option = this.normalizedOptions[i];
        if (this.model.indexOf(option.value) > -1) {
          this.toggle[i] = true;
        }
      }
    }
  },
  watch: {
    model() {
      this.updateToggle();
    }
  }
}
</script>

<style lang="scss">
.c-multi-select {
  .dropdown-toggle {
    position: relative;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 0.75rem;
    top: 1.125rem;
  }

  .dropdown-toggle {
    padding-right: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-menu {
    .dropdown-item {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
