<template>
  <div class="input-errors">
    <div v-for="error in errors" class="small form-text text-danger">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: "InputErrors",
  props: {
    errors: {
      type: Array,
      items: {
        type: String,
        required: true
      },
      default: []
    }
  }
}
</script>
