<template>
  <div class="progress-bar__container" ref="progressContainer">
    <div class="progress mt-2 mb-2 " style="position: relative;  z-index: 1;"
         :style="{ height: progressBarHeight + 'px' }">
      <div class="progress-bar  progress-bar__invested"
           role="progressbar"
           :style="{ width: progressBarWidth + '%'}"
           :aria-valuenow="progressBarValueNow"
           aria-valuemin="0"
           aria-valuemax="100"
           @mousemove="updateTooltipPosition($event, 'invested')"
           @mouseleave="hideTooltip"
      ></div>
      <div   class="progress-bar progress-bar__reserved "
           role="progressbar"
           :style="{ width: progressBarValueReserved + '%' }"
           :aria-valuenow="progressBarValueNow"
           aria-valuemin="0"
           aria-valuemax="100"
           @mousemove="updateTooltipPosition($event, 'reserved')"
           @mouseclick="toggleTooltip"
           @mouseleave="hideTooltip"
      ></div>
      <div class="progress-bar progress-bar__free "
           role="progressbar"
           :style="{ width: progressBarValueFree + '%' }"
           :aria-valuenow="progressBarValueNow"
           aria-valuemin="0"
           aria-valuemax="100"
           @mousemove="updateTooltipPosition($event, 'free')"
           @mouseclick="toggleTooltip"
           @mouseleave="hideTooltip"
      ></div>
    </div>
    <div v-if="tooltipVisible"
         class="progress-bar__hint"
         :style="{ top: tooltipPosition.top + 'px', left: tooltipPosition.left + 'px' }">
      {{ tooltipText }}
    </div>
  </div>
</template>

<script>
import {formatAmount} from "@/components/common";
import {Money as ApiMoney} from "@/utils/api-client";

export default {
  name: "InvestProgressBar",
  props: {
    investedSum: null,
    loanSum: null,
    reservationSum: null,
    progressBarHeight: null
  },
  data() {
    return {
      tooltipVisible: false,
      tooltipText: '',
      tooltipPosition: {
        top: null,
        left: null
      }
    };
  },
  computed: {
    progressBarWidth() {
      return (this.investedSum.amount / this.loanSum.amount * 100)
    },
    progressBarValueNow() {
      return Math.round(this.investedSum.amount / this.loanSum.amount * 100)
    },
    progressBarValueReserved() {
      return (this.reservationSum.amount / this.loanSum.amount * 100);
    },
    progressBarValueFree() {
      return (this.freeSum.amount / this.loanSum.amount * 100);
    },
    freeSum() {
      const freeSum = this.loanSum.amount - this.reservationSum.amount - this.investedSum.amount > 0
          ? this.loanSum.amount - this.reservationSum.amount - this.investedSum.amount : 0;
      return new ApiMoney(freeSum, this.loanSum.currencyCode);
    },

  },
  methods: {
    showTooltip(text) {
      this.tooltipText = text;
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    updateTooltipPosition(event, type) {
      this.tooltipVisible = true;
      switch (type) {
        case 'invested':
          this.tooltipText = 'Набрано: ' + formatAmount(this.investedSum.amount / 100) + ' ₽';
          break;
        case 'reserved':
          this.tooltipText = 'Сумма в обработке: ' + formatAmount(this.reservationSum.amount / 100) + ' ₽'
          break;
        case 'free':
          this.tooltipText = 'Свободный остаток: ' + formatAmount(this.freeSum.amount / 100) + ' ₽';
          break;
      }
      console.log('width', this.progressBarWidth)
      console.log('nowVal', this.progressBarValueNow)
      console.log('resValue', this.progressBarValueReserved);
      console.log('freeSum', this.progressBarValueFree);
      console.log('res sum', this.reservationSum.amount);
      const container = this.$refs.progressContainer.getBoundingClientRect();

      // Вычисляем координаты тултипа относительно контейнера
      const offsetX = -50;  // Смещаем чуть правее курсора
      const offsetY = -30; // Смещаем чуть выше курсора

      // Используем координаты мыши относительно окна и вычитаем позицию контейнера
      this.tooltipPosition.top = event.clientY - container.top + offsetY;
      this.tooltipPosition.left = event.clientX - container.left + offsetX;
    },
    toggleTooltip() {
      this.tooltipVisible = !this.tooltipVisible;
      this.tooltipPosition.top = 0;
      this.tooltipPosition.left = 0;
    },
  }
}
</script>
<style lang="scss" scoped>
.progress-bar {
  &__hint {
    position: absolute;
    padding: 5px 10px;
    background-color: #252c54;
    color: #fff;
    border-radius: 0.25rem;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
    z-index: 100;
  }
  &__container {
    position: relative;
  }
  &__invested {
    background-color: var(--primary-green-color);
    z-index:3
  }
  &__reserved {
    background-color: var(--primary-green-color-hover);
    opacity: 0.4;
    z-index: 2
  }
  &__free {
    background-color: var(--gray-background-color);
    opacity: 0.4;
    z-index: 1
  }
}
</style>
