<template>
  <div v-if="!show">
    <div class="modal show modal-rules"
         tabindex="-1"
    >
      <div class="overflow-block container-xl" ></div>

      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            Уважаемые инвесторы!<br/>
<!--            В соответствии с <public-link to="/page/using-rules"> Правилами Платформы Lendly от 31.03.2022 г.</public-link>-->
<!--            инвестирование на первичном рынке доступно для ИП, ЮЛ и квалифицированных инвесторов.-->
            Для инвестирования на первичном рынке необходимо предоставить доверенность, выданную в нотариальной форме на имя ООО "Лендли",
            как управляющего залогом, на заключение Договора ипотеки в качестве залогодержателя,
            его государственной регистрации и последующего управления залогом. Для получения шаблона
            доверенности просьба отправить запрос на <a href="mailto:support@lendly.ru">support@lendly.ru</a>
          </div>
        </div>
      </div>
    </div >
  </div>
</template>

<script>
import {PublicLink} from "@/components/common";

export default {
  components:{
    PublicLink
  },
  name: "ModalDialog",
  inject: ["profile"],
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
}
</script>

<style scoped>

.overflow-block {
  background-color: #6c757d;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.modal-rules {
  z-index: 20;
  position: absolute;
  text-align: center;
  vertical-align: center;
  margin: auto;
  left: 0;
  right: 0;
  display: inline;
}

</style>