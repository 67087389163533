<template>
  <div class="data-loading">
    <div v-if="loading" :class="{ 'data-loading-lock': lock }">
      <div class="data-loading-message text-center">
        <slot><i>Загрузка...</i></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataLoading",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss">
.data-loading {
  .data-loading-lock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.5);

    .data-loading-message {
      margin: 1rem auto auto;
      padding: 1rem;
      width: 30rem;
      max-width: 80%;
      background: #fff;
      border-radius: 0.25rem;
    }
  }
}
</style>
