<template>
  <div class="page-not-found">
    <div class="container-xl">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Страница не найдена</div>
        </div>
        <div class="card-body">
          <router-link :to="{ name: 'home' }">На главную</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style lang="scss">
.page-not-found {
  min-height: calc(100vh - 398px);
}
</style>
