<template>
  <span @click="toggleTooltip"
        @mouseenter="showOnHover"
        @mouseleave="hideOnHover"
        class="c-tooltip">
    <slot name="icon">
      <i class="bi-info-circle text-success"></i>
    </slot>
    <span v-show="showTooltip" :class="['c-tooltip-text', textPosition]">{{ text }}</span>
  </span>
</template>

<script>
import {TooltipTextPosition} from "@/utils/api-client/enums/TooltipTextPosition";

export default {
  name: "CTooltip",
  props: {
    text: {
      type: String,
      required: true
    },
    textPosition: {
      type: String,
      default: TooltipTextPosition.MIDDLE_BOTTOM
    }
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  methods: {
    toggleTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    showOnHover() {
      this.showTooltip = true;
    },
    hideOnHover() {
      this.showTooltip = false;
    },
  }
}
</script>

<style lang="scss">
.c-tooltip {
  position: relative;
  display: inline-block;

  .c-tooltip-text {
    //visibility: hidden;
    width: 18rem;
    max-width: 90vw;
    background-color: #252c54;
    color: #fff;
    border-radius: 0.25rem;
    padding: 0.5rem;
    position: absolute;
    z-index: 3;
    overflow: hidden;
    margin: 1.75rem 0 0 -9rem;
    text-overflow: ellipsis;
  }

  .middle-bottom {
    margin: 1.75rem 0 0 -9rem
  }

  .bottom-right {
    @media (max-width: 768px) {
      margin: 1.75rem 0 0 -1rem;
    }
  }
}

//.c-tooltip:hover {
//  .c-tooltip-text {
//    visibility: visible;
//  }
//}
</style>
