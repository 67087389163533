import { ValidationError } from "@/utils/api-client";

type AggregatedErrors = {
  [key: string]: Array<string>;
};

export function aggregateValidationErrors(err: ValidationError): AggregatedErrors {
  const errors: AggregatedErrors = {};
  err.errors.forEach(({ propertyPath, message }) => {
    errors[propertyPath] = [...errors[propertyPath] || [], message]
  });
  return errors;
}
