<template>
 <div>{{timeToPrettyString(this.leftDays, this.leftMonths)}}</div>
</template>
<script>
export default {
  name: "TimeLeftField",
  props: {
    leftMonths: {
      type: Number,
      default() {
        return 0;
      }
    },
    leftDays: {
      type: Number,
      default() {
        return 0;
      }
    },
  },
  methods: {
    timeToPrettyString(leftDays, leftMonths){
      if ((leftDays <= 31) && (leftDays > 0) && (leftMonths <= 1)){
        let daysStr = leftDays < 4 ? (leftDays > 1 ? "дня" : "день") : "дней";
        return `${leftDays} ${daysStr}`;
      } else {
        return `${leftMonths} мес.`;
      }
    }
  }
}
</script>
