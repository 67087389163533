<template>
  <div v-if="show" class="dialog-modal">
    <div class="modal fade show d-block"
         data-backdrop="static"
         data-keyboard="false"
         tabindex="-1"
         role="dialog"
         aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <slot name="title"></slot>
            </div>
            <div class="ml-auto">
              <a @click.stop.prevent="close" class="text-muted" href="#">
                <i class="bi-x"></i>
              </a>
            </div>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <slot name="buttons">
              <slot name="button-close">
                <button @click="close" type="button" class="btn btn-outline-danger">
                  Закрыть
                </button>
              </slot>
              <slot name="button-action"></slot>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
export default {
  name: "DialogModal",
  data() {
    return {
      show: false
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    }
  }
}
</script>

<style lang="scss">
.dialog-modal {
  .modal-dialog {
    .modal-content {
      min-height: 20rem;
    }
  }
}
</style>
