<template>
  <div class="paginated-list position-relative" @update="update">
    <div v-if="filter" class="filter">
      <form @submit.stop.prevent="submitFilter">
        <slot name="filter"></slot>
      </form>
    </div>
    <data-loading :loading="dataLoading" :lock="dataLoadingLock">
      <slot name="loading"><i>Загрузка...</i></slot>
    </data-loading>
    <div v-if="!dataLoading && count === 0" class="text-center">
      <slot name="no-items">
        <div><i>Нет данных</i></div>
        <router-link v-if="page !== 1" :to="generateFirstPageUrl()">На первую страницу</router-link>
      </slot>
    </div>
    <slot></slot>
    <paginator v-if="count > 0"
               :total-count="totalCount"
               :page="page"
               :per-page="perPage"
               :display-single-page="displaySinglePage"
               :first-page="config.firstPage"
               :default-per-page="config.defaultPerPage"
               :page-param="prefix + config.pageParam"
               :per-page-param="prefix + config.perPageParam"/>
  </div>
</template>

<script>
import {buildRouterQuery, parseRouterQuery} from "@/utils";
import DataLoading from "./DataLoading";
import Paginator from "./Paginator";

export default {
  name: "PaginatedList",
  components: {
    DataLoading,
    Paginator
  },
  props: {
    filter: {
      type: Object
    },
    count: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    dataLoading: {
      type: Boolean,
      default: false
    },
    dataLoadingLock: {
      type: Boolean,
      default: false
    },
    displaySinglePage: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      config: {
        firstPage: 1,
        defaultPerPage: 25,
        pageParam: "page",
        perPageParam: "per_page",
        filterParam: "filter"
      },
      page: null,
      perPage: null
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    generateFirstPageUrl() {
      const {path, query} = this.$route;
      const queryParams = {...query};
      queryParams[this.pageParam] = this.config.firstPage;
      return {path, query: queryParams};
    },
    update(query) {
      query = query || this.$route.query;
      this.page = parseInt(query[this.pageParam] || [this.config.firstPage]);
      this.perPage = parseInt(query[this.perPageParam] || [this.config.defaultPerPage]);

      if (query[this.filterParam]) {
        const filter = parseRouterQuery(query[this.filterParam]);
        for (const key in filter) {
          if (filter.hasOwnProperty(key)) {
            this.filter[key] = filter[key];
          }
        }
      }

      this.$emit("update", this.page, this.perPage, this.filter);
    },
    submitFilter() {
      const {path, query} = this.$route;
      const queryParams = {...query};

      delete queryParams[this.pageParam];
      queryParams[this.filterParam] = buildRouterQuery(this.filter);

      this.$router.push({path, query: queryParams});
      this.update(queryParams);
    }
  },
  computed: {
    filterParam() {
      return this.prefix + this.config.filterParam;
    },
    pageParam() {
      return this.prefix + this.config.pageParam;
    },
    perPageParam() {
      return this.prefix + this.config.perPageParam;
    }
  },
  watch: {
    "$route.query"(newValue, oldValue) {
      if (
          newValue[this.pageParam] !== oldValue[this.pageParam] ||
          newValue[this.perPageParam] !== oldValue[this.perPageParam]
      ) {
        this.update(newValue);
      }
    }
  }
}
</script>
